import {css} from 'styled-components'

import {colors, focusRingSizes} from '@/theme'

export type FocusStylesSpread = keyof typeof focusRingSizes

export const focusStyles = (spread: FocusStylesSpread = 'thin') => css`
    border-color: transparent;

    /* Set outline as Windows high-contrast mode fallback */
    outline: transparent solid 2px;
    box-shadow: 0 0 0 ${focusRingSizes[spread]} ${colors.focusRing};
`

export const ellipsisStyles = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
