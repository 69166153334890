import React from 'react'
import styled, {keyframes} from 'styled-components'

import {tokens} from '@/theme'

export const LoadingDots = () => {
    return (
        <DotWrapper>
            <Dot delay={0} />
            <Dot delay={0.16} />
            <Dot delay={0.32} />
        </DotWrapper>
    )
}

export const DotWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    grid-template-columns: repeat(3, auto);
    column-gap: ${tokens.spacing8};
`

const loadAnimation = keyframes`
    0%,
    80%,
    100% {
        transform: scale3d(0.3, 0.3, 1);
    }
    40% {
        transform: scale3d(1.2, 1.2, 1);
    }
`

interface DotProps {
    delay: number
}

export const Dot = styled.div<DotProps>`
    width: 8px;
    height: 8px;
    background-color: ${tokens.shade600};
    border-radius: ${tokens.circle};
    animation: ${loadAnimation} 1.8s infinite ease-in-out;
    animation-fill-mode: both;
    animation-delay: ${({delay}) => delay}s;
`
