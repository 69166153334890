import localeCodeToUrl from './locale-to-url'
import {removeTrailingSlash} from './remove-trailing-slash'
import type {LocaleCode} from '../locale/locales'
import type {Document} from '../types/document'
import type {LinkResolver} from '../types/link-resolver'

export enum DocTypesEnum {
    ABOUT_US_PAGE = 'about_us_page',
    APPLE_PAY_PAGE = 'apple_pay_page',
    BILLS_STATUS = 'bills_status',
    BILLS_TRACKER = 'bills_tracker',
    INVOICES_APPROVALS = 'invoices_approvals',
    PARTNERSHIP_CASE = 'partnership_case',
    MEETING_BOOKER_PAGE = 'meeting_booker_page',
    CUSTOMER_CASE = 'customer_case',
    CUSTOMERS_PAGE = 'customers_page',
    DEMO_PAGE = 'demo_page',
    EBOOK = 'ebook',
    EXPENSE_POLICY_BUILDER = 'expense_policy_builder',
    EXPENSE_REPORT_GENERATOR = 'expense_report_generator',
    EXPENSE_REPORT_GENERATOR_EXTERNAL_VARIANT = 'expense_report_generator_external_variant',
    EXPERIMENTAL_LANDING_PAGE = 'experimental_landing_page',
    EXPLORE_PRODUCT = 'explore_product',
    FAQ_PAGE = 'faq_page',
    FETCH_PAGE = 'fetch_page',
    HOMEPAGE_V3 = 'homepage_v3',
    HOMEPAGE_V4 = 'homepage_v4',
    GETTING_STARTED_EXPERIENCE = 'getting_started_experience',
    JOB = 'job',
    JOB_LISTING = 'job_listing',
    LEGAL_PAGE = 'legal_page',
    LEGAL_PAGE_COOKIE_POLICY = 'legal_page_cookie_policy',
    LEGAL_PAGE_DSR = 'legal_page_dsr',
    LEGAL_PAGE_GDPR = 'legal_page_gdpr',
    LEGAL_PAGE_THIRD_PARTY_LICENCES = 'legal_page_third_party_licences',
    NOT_FOUND_PAGE = 'not_found_page',
    ON_DEMAND_RESOURCE_PAGE = 'on_demand_resource_page',
    PARTNER = 'partner',
    PARTNER_BIGFORM_PAGE = 'partner_bigform_page',
    PERKS_INTERNAL_PAGE = 'perks_internal_page',
    PERKS_PAGE = 'perks_page',
    PRICING_PAGE = 'pricing_page',
    RESOURCE_DETAIL_PAGE = 'resource_detail_page',
    ROI_CALCULATOR = 'roi_calculator',
    SLICE_PAGE = 'slice_page',
    SUBSCRIPTIONS_PAGE = 'subscriptions_page',
    WHY_PLEO_PAGE = 'why_pleo_page',
    SECONDARY_HOMEPAGE = 'secondary_homepage',
    SECONDARY_LEGAL_PAGE = 'secondary_legal_page',
    SECONDARY_PRICING_PAGE = 'secondary_pricing_page',
    SECONDARY_NOT_FOUND_PAGE = 'secondary_not_found_page',
    SKILL_EVALUATION_TOOL = 'skill_evaluation_tool',
}

export enum PageUID {
    HOMEPAGE = 'homepage',
    LP = 'lp',
}

const hasParentPage = (doc: Partial<Document>): doc is Document => {
    return !!doc.data?.parent_document?.uid
}

const hasSecondaryLocaleField = (doc: Partial<Document>): doc is Document => {
    return !!doc.data?.localeCode
}

export const linkResolver: LinkResolver = (doc) => {
    const localize = (path: string) =>
        removeTrailingSlash(
            `/${localeCodeToUrl(doc.lang as LocaleCode)}${path === '/' ? '' : path}`,
        )

    switch (doc.type) {
        case DocTypesEnum.HOMEPAGE_V4: {
            if (doc.uid === PageUID.HOMEPAGE) {
                return localize('/')
            }

            // AB test page
            return localize(`/${PageUID.LP}/${doc.uid}`)
        }

        case DocTypesEnum.HOMEPAGE_V3: {
            if (doc.lang === 'en-us' && doc.uid === PageUID.HOMEPAGE) {
                return localize('/')
            }

            // AB test page
            return localize(`/${PageUID.LP}/${doc.uid}`)
        }

        case DocTypesEnum.SECONDARY_HOMEPAGE: {
            if (!hasSecondaryLocaleField(doc)) {
                if (process.env.GATSBY_ACTIVE_ENV === 'production') {
                    throw new Error(`Secondary Homepage is missing localeCode`)
                } else {
                    return 'UNKNOWN_DOCUMENT_TYPE'
                }
            }

            return `/${localeCodeToUrl(doc.data?.localeCode as LocaleCode)}`
        }

        case DocTypesEnum.EBOOK: {
            return localize(`/ebooks/${doc.uid}`)
        }

        case DocTypesEnum.FETCH_PAGE: {
            return localize('/fetch')
        }

        case DocTypesEnum.PERKS_PAGE: {
            return localize('/perks')
        }

        case DocTypesEnum.PERKS_INTERNAL_PAGE: {
            return localize('/perks-internal')
        }

        case DocTypesEnum.SUBSCRIPTIONS_PAGE: {
            return localize('/subscriptions')
        }

        case DocTypesEnum.PRICING_PAGE: {
            if (doc.uid === 'pricing') {
                return localize(`/${doc.uid}`)
            }

            // AB test page
            return localize(`/lp/${doc.uid}`)
        }

        case DocTypesEnum.SECONDARY_PRICING_PAGE: {
            if (!hasSecondaryLocaleField(doc)) {
                if (process.env.GATSBY_ACTIVE_ENV === 'production') {
                    throw new Error(`Secondary Homepage is missing localeCode`)
                } else {
                    return 'UNKNOWN_DOCUMENT_TYPE'
                }
            }

            return `/${localeCodeToUrl(doc.data?.localeCode as LocaleCode)}/pricing`
        }

        case DocTypesEnum.WHY_PLEO_PAGE: {
            return localize('/whypleo')
        }

        case DocTypesEnum.CUSTOMERS_PAGE: {
            return localize('/customers')
        }

        case DocTypesEnum.ABOUT_US_PAGE: {
            return localize('/about')
        }

        case DocTypesEnum.EXPENSE_POLICY_BUILDER: {
            return localize('/expense-policy-builder')
        }

        case DocTypesEnum.ROI_CALCULATOR: {
            return localize('/expense-report-roi-calculator')
        }

        case DocTypesEnum.EXPENSE_REPORT_GENERATOR: {
            return localize('/expense-report-generator')
        }

        case DocTypesEnum.LEGAL_PAGE: {
            return localize('/legal')
        }

        case DocTypesEnum.SECONDARY_LEGAL_PAGE: {
            if (!hasSecondaryLocaleField(doc)) {
                if (process.env.GATSBY_ACTIVE_ENV === 'production') {
                    throw new Error(`Secondary Homepage is missing localeCode`)
                } else {
                    return 'UNKNOWN_DOCUMENT_TYPE'
                }
            }

            return `/${localeCodeToUrl(doc.data?.localeCode as LocaleCode)}/legal`
        }

        case DocTypesEnum.LEGAL_PAGE_GDPR: {
            return localize('/legal/gdpr')
        }

        case DocTypesEnum.LEGAL_PAGE_DSR: {
            return localize('/legal/dsr')
        }

        case DocTypesEnum.LEGAL_PAGE_COOKIE_POLICY: {
            return localize('/legal/cookie-policy')
        }

        case DocTypesEnum.LEGAL_PAGE_THIRD_PARTY_LICENCES: {
            return localize('/legal/third-party-licences')
        }

        case DocTypesEnum.FAQ_PAGE: {
            return localize('/faq')
        }

        case DocTypesEnum.CUSTOMER_CASE: {
            return localize(`/customer-cases/${doc.uid}`)
        }

        case DocTypesEnum.PARTNERSHIP_CASE: {
            return localize(`/partnership-cases/${doc.uid}`)
        }

        case DocTypesEnum.PARTNER: {
            return localize(`/partner/${doc.uid}`)
        }

        case DocTypesEnum.NOT_FOUND_PAGE: {
            return localize('/404')
        }

        case DocTypesEnum.SECONDARY_NOT_FOUND_PAGE: {
            if (!hasSecondaryLocaleField(doc)) {
                if (process.env.GATSBY_ACTIVE_ENV === 'production') {
                    throw new Error(`Secondary Homepage is missing localeCode`)
                } else {
                    return 'UNKNOWN_DOCUMENT_TYPE'
                }
            }

            return `/${localeCodeToUrl(doc.data?.localeCode as LocaleCode)}/404`
        }

        case DocTypesEnum.BILLS_TRACKER: {
            return localize('/bills-tracker')
        }

        case DocTypesEnum.BILLS_STATUS: {
            return localize('/bills-status')
        }

        case DocTypesEnum.INVOICES_APPROVALS: {
            return localize('/invoices-approvals')
        }

        case DocTypesEnum.EXPENSE_REPORT_GENERATOR_EXTERNAL_VARIANT: {
            return localize('/expense-report-generator-external')
        }

        case DocTypesEnum.JOB: {
            return localize(`/careers/jobs/${doc.uid}`)
        }

        case DocTypesEnum.JOB_LISTING: {
            return localize('/careers/jobs')
        }

        case DocTypesEnum.ON_DEMAND_RESOURCE_PAGE: {
            return localize(`/webinars/${doc.uid}`)
        }

        case DocTypesEnum.GETTING_STARTED_EXPERIENCE: {
            return localize('/getting-started')
        }

        case DocTypesEnum.MEETING_BOOKER_PAGE: {
            return localize('/book-a-demo')
        }

        case DocTypesEnum.SKILL_EVALUATION_TOOL: {
            return localize('/skill-evaluation-tool')
        }

        case DocTypesEnum.DEMO_PAGE:
        case DocTypesEnum.PARTNER_BIGFORM_PAGE:
        case DocTypesEnum.APPLE_PAY_PAGE:
        case DocTypesEnum.EXPERIMENTAL_LANDING_PAGE:
        case DocTypesEnum.RESOURCE_DETAIL_PAGE:
        case DocTypesEnum.EXPLORE_PRODUCT: {
            return localize(`/${doc.uid}`)
        }

        case DocTypesEnum.SLICE_PAGE: {
            // relationship fields
            if (doc?.url && doc?.url !== 'UNKNOWN_DOCUMENT_TYPE') {
                return doc.url
            }

            // page generation and rich text links
            if (hasParentPage(doc)) {
                return localize(`/${doc?.data?.parent_document?.uid}/${doc.uid}`)
            }

            return localize(`/${doc.uid}`)
        }

        default: {
            return 'UNKNOWN_DOCUMENT_TYPE'
        }
    }
}
