import countries from 'i18n-iso-countries'

import type {CountryCode} from '@/locale/locales'
import {getDefaultLocale} from '@/providers/locale'
import type {HubspotState} from '@/utils/submit-hubspot-form'

export const useMappedHubspotPayload = () => {
    const defaultLocale = getDefaultLocale()
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

    return (payload: HubspotState) => ({
        ...payload,
        ...(payload.country && {
            country:
                payload.country === 'Other'
                    ? payload.country
                    : countries.getName(payload.country as CountryCode, defaultLocale.language),
        }),
    })
}
