/**
 * Returns the appropriate field based on the current environment
 */

interface Props<T> {
    production: T
    staging: T
}

const getEnvironmentField = <T,>({production, staging}: Props<T>) => {
    let field = process.env.GATSBY_ACTIVE_ENV === 'production' ? production : staging

    if (!field) {
        throw new Error('SlackNotification: Environment field is missing')
    }

    return field
}

export default getEnvironmentField
