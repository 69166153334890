import Cookies from 'js-cookie'

import {cookiesMap} from '@helios/website/src/config/cookies'

import {isBrowser} from './browser'

/**
 * Extracts split test experiments buckets from a cookie set by the viewer-response lambda
 * and exposes it as a array of [experimentName, experimentValue] tuples
 */
export function getRawExperiments() {
    /**
     * The value of this cookie should be a comma-separated list of currently running
     * experiments, containing the experiment name and the given bucket's value, e.g.
     * 'bigform:new-bigform,ebooks:old-ebooks'
     */
    return formatCookieValue(Cookies.get(cookiesMap.splitTestCookieName))
}

export function getRawEncounteredExperiments() {
    /**
     * The value of this cookie should be a comma-separated list of currently running
     * experiments, containing the experiment name and if the user has encountered the
     * experiment, e.g. 'bigform:on,ebooks:off'
     */
    return formatCookieValue(Cookies.get(cookiesMap.splitTestEncounteredCookieName))
}

/**
 * Extracts split test experiments buckets from a cookie set by the viewer-response lambda
 * and exposes it as a dictionary of experiment values
 */
export function getExperiments(): Record<string, string> {
    const experiments = getRawExperiments() ?? []

    /**
     * Creates an object with each experiment as a separate property, e.g.
     * {
     *   "bigform": "new-bigform",
     *   "ebooks": "old-ebooks"
     * }
     */
    return Object.fromEntries(experiments)
}

/**
 * Extracts page split test experiments paths from a cookie set by the viewer-response lambda
 * and exposes it as a array of path regex patterns
 */
export function getPageSplitExperimentPaths() {
    /**
     * The value of this cookie should be a stringified array of path regex
     * patterns for every currently running page split experiment
     */
    const experimentsCookie = Cookies.get(cookiesMap.splitTestPathsCookieName)

    if (!isBrowser || !experimentsCookie) {
        return null
    }

    try {
        const paths = JSON.parse(experimentsCookie)

        // Make sure it's an array
        if (!Array.isArray(paths)) {
            return null
        }

        // Make sure it's an array of strings
        if (!allItemsAreStrings(paths)) {
            return null
        }

        return paths
    } catch (error) {
        return null
    }
}

const allItemsAreStrings = (arr: unknown[]): arr is string[] =>
    arr.every((path) => typeof path === 'string')

const formatCookieValue = (value: string | undefined) => {
    if (!isBrowser || !value) {
        return null
    }

    return value.split(',').map((pageSplitExperiment) => {
        return pageSplitExperiment.split(':') ?? []
    })
}
