/**
 * Normalises a string to be used as a valid html id or query selector.
 * Note that we normalise it further by replacing underscores with hyphens and lowercasing it
 * in order to make it more readable since it could appear as a url hash.
 * @param value
 * @returns A normalised value that is trimmed, lowercased and has its spaces, dashes,
 * underscores and unsafe query selector characters replaced with hyphens.
 */
export const stringToSlug = (value: string) =>
    value
        .toLowerCase()
        .trim()
        .replace(/[\s_-]+/g, '-')
        .replace(/[!"#$%&'()*+,./:;<=>?@[\]^`{|}~]+/g, '-')
        .replace(/^-+|-+$/g, '')
