import type {GatsbyBrowser} from 'gatsby-browser'

let nextRoute = ''

export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = ({prevLocation}) => {
    /**
     * Gatsby has a bug where the values of "prevLocation" and "location" are inverted.
     * So we have to get the destination of the route, from prevLocation instead of 'location'
     * More info https://github.com/gatsbyjs/gatsby/issues/34298
     */
    nextRoute = prevLocation ? prevLocation.pathname : ''
}

window.addEventListener('unhandledrejection', (event) => {
    console.error(`UNHANDLED PROMISE REJECTION: ${event.reason}`)

    if (/loading chunk \d* failed./i.test(event.reason)) {
        if (nextRoute) {
            window.location.pathname = nextRoute
        }
    }
})
