import {usePageInfo} from '@/providers/page-info'

const useParams = () => {
    const {location} = usePageInfo()
    const params = new URLSearchParams(location?.search)

    return params
}

export default useParams
