import styled, {css} from 'styled-components'

import {text} from '@/components/text'
import checkIconBlack from '@/images/icons/check-black.svg'
import checkIconPink from '@/images/icons/check-pink.svg'
import {tokens} from '@/theme'
import mq from '@/utils/media-query'

const checkIcons = {
    pink: checkIconPink,
    black: checkIconBlack,
}

type Color = keyof typeof checkIcons

export const checkmarkList = (color: Color = 'pink') => css`
    display: grid;
    gap: ${tokens.spacing12};
    padding: 0;

    li {
        display: flex;

        &::before {
            margin-right: ${tokens.spacing12};
            content: url(${checkIcons[color]});
        }

        &,
        * {
            ${text({$variant: 'paragraph-base'})}
        }
    }

    ${mq.min('sm')} {
        gap: ${tokens.spacing20};
    }
`

interface Props {
    color?: Color
}

export const CheckmarkList = styled.ul<Props>`
    ${(props) => checkmarkList(props.color)}
`
