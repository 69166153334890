export enum MarketingCampaigns {
    Cashback = 'FY22 INT - Cashback Launch',
    MultiEntity = 'FY22Q3 - INT - Multi Entity Launch',
    InteractiveDemo = 'FY22Q4 - WEB - Navattic - Interactive Demo',
}

export enum Events {
    Blog = 'Blog',
    CashbackLandingPage = 'Cashback Landingpage',
    MultiEntityLandingPage = 'Multi Entity Landingpage',
}

export enum LifecycleStages {
    MQL = 'marketingqualifiedlead',
    Lead = 'lead',
    Subscriber = 'subscriber',
}

export enum LatestLeadSourceCaptureType {
    BlogPleoDigest = 'Blog - Pleo Digest',
    FreeDemo = 'Free Demo',
    SelfOnboarding = 'Self - Onboarding.  Sign up',
    SelfOnboardingPartner = 'Self - Onboarding - Sign up_Partner',
    PartnerBigForm = 'bigform-partner',
    Ebooks = 'Ebooks',
}

export enum Integrations {
    Travelperk = 'travelperk',
    Bolt = 'bolt',
    Sesame = 'sesame',
    Netsuite = 'netsuite',
    Twinfield = 'twinfield',
    Datev = 'datev',
    Xero = 'xero',
    Quickbooks = 'quickbooks',
    Fortnox = 'fortnox',
    Vismaeconomic = 'visma_economic',
    Vismaeekonomi = 'vismaeekonomi',
    Exactonline = 'exactonline',
    Holded = 'holded',
    Personio = 'personio',
}

export enum LeadType {
    Partner = 'Partner lead',
    AccountingPartner = 'Partner Self Signup',
    Sales = 'Sales Lead',
}

export enum HubspotPartnerChannel {
    TechnologyPartners = 'Technology partners',
    AccountingPartner = 'Accounting partners',
    AffiliatePartner = 'Affiliate',
    ConsultingPartner = 'Consulting partners',
    Marketplace = 'Pleo Marketplace',
}

export enum HubspotTypeOfInfluence {
    PartnerSource = 'Partner-sourced',
    SalesSource = 'Sales-sourced',
}

export enum HubspotPartnerSegment {
    Referral = 'Referral',
}

export enum HubspotPartnershipType {
    Distribution = 'Distribution',
}

export enum LeadInterestLevel {
    Interested = 'Interested, may want more information',
}
