module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_babel-plugin-styled-components@2.1.4_@babel+core@7.26._am5g3bjqt7beugbzrqn52phlv4/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"disableVendorPrefixes":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-prismic-previews@5.3.1_wi2bje5uuxc6wmtqeu545bmvai/node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"commercial-helios","accessToken":"MC5YdGU0UGhBQUFDTUFMOFFj.FO-_vTlQ77-977-977-9UFkl77-977-9IUpa77-977-977-977-9QQnvv73vv71677-977-977-977-977-977-977-977-9","fetchLinks":["slice_page.parent_document"],"toolbar":"new","promptForAccessToken":true,"apiEndpoint":"https://commercial-helios.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-segment-js@5.0.0_gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+web_okwuti6gmyxhbalronypxeb5fe/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"host":"https://cdn.insights.pleo.io","prodKey":"D9B1Vzgq3NqiQYAHGTa5vSvPzzJ3MfEz","devKey":"D9B1Vzgq3NqiQYAHGTa5vSvPzzJ3MfEz","trackPage":false,"manualLoad":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-hubspot@2.0.0_gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+webpac_ta64qesfzf2f77zo67k6erbmlu/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"20764648","respectDNT":true,"productionOnly":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-datadog-rum@1.0.1/node_modules/gatsby-plugin-datadog-rum/gatsby-browser.js'),
      options: {"plugins":[],"applicationId":"450ad43c-54b6-4fe8-a2f4-e9a0395b38a4","clientToken":"pub828d87fb3413da442efee5cbed22399a","site":"datadoghq.eu","service":"commercial-website","sampleRate":100,"enabled":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+webpack@5.28.5_@swc+core@1.7.39_@s_nopxvmnahq3nvhocpl7bxgmv7e/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
