import type {GatsbyBrowser} from 'gatsby'

import {setParamCookies} from '@/config/param-cookies'
import {page} from '@/utils/analytics'
import {updateSessionTrackingCookies} from '@/utils/track-session'

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({location}) => {
    if (location.search) {
        const urlParams = new URLSearchParams(location.search)

        setParamCookies(urlParams)
    }

    // The page path without the locale segment, e.g. `/pricing` instead of `/en/pricing`
    const rawPath = `/${location.pathname.split('/').slice(2).join('/')}`

    page({
        rawPath,
    })

    updateSessionTrackingCookies()
}
