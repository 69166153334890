import styled, {css} from 'styled-components'

import {colors, fonts, radii, tokens} from '@/theme'

import {focusStyles} from './utils'

export interface InputStylesProps {
    $hasError?: boolean
    value?: JSX.IntrinsicElements['input']['value']
}

const dateInputPlaceholder = css`
    &::before {
        color: ${colors.text.placeholder};
        content: attr(placeholder);
        user-select: none;
    }
`

export const requiredFieldStyles = css`
    &::after {
        margin-left: ${tokens.spacing4};
        content: '*';

        /* Used by Chromium browsers for hiding the content from screen readers by providing an alt text */
        content: '*' / '';
    }
`

type RequiredLabelProps = {
    $required?: boolean
}

export const Label = styled.label<RequiredLabelProps>`
    font-family: ${fonts.families.secondary};
    font-size: ${tokens.fontLarge};

    ${(props) => props.$required && requiredFieldStyles}
`

export const inputStyles = ({$hasError, value}: InputStylesProps) => {
    const errorStyles = css`
        &,
        &:focus {
            border-color: ${colors.border.error};
        }
    `

    return css`
        width: 100%;
        padding: ${tokens.spacing12} ${tokens.spacing20};
        font-family: ${fonts.families.secondary};
        font-size: ${tokens.fontLarge};
        line-height: 1.5;
        color: ${colors.textInput.default.foreground};
        word-break: initial;
        background: ${colors.textInput.default.background};
        border: ${({theme}) => theme.borders.transparent};
        border-radius: ${radii.arc8};
        box-shadow: none;

        &,
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
        }

        &[type='number'] {
            appearance: textfield;
        }

        &[type='date'] {
            /* Hack to fix iOS bug where date input has shrunk height. See https://bugs.webkit.org/show_bug.cgi?id=198959 */
            min-height: 45.5px;

            /* Hack to target only iOS Safari */
            @supports (-webkit-touch-callout: none) {
                /* Date inputs on iOS don't show placeholders, so we mock one */
                ${!value && dateInputPlaceholder}
            }
        }

        &::placeholder {
            color: ${colors.text.placeholder};
            opacity: 1;
        }

        &:focus {
            ${focusStyles()}
        }

        &:disabled,
        &:disabled::placeholder {
            color: ${colors.textInput.disabled.foreground};
            background: ${colors.textInput.disabled.background};
        }

        ${$hasError && errorStyles}
    `
}
