import {css} from 'styled-components'

import {fonts, breakpoints} from '@/theme'

interface Sizes {
    [key: string]: number[]
}

const fluid =
    <T extends Sizes>(sizes: T) =>
    (minVw: number, maxVw: number) =>
    (property: string, size: keyof T) => {
        const [minFontSize, maxFontSize] = sizes[size]

        return css`
            ${property}: ${minFontSize}px;
            /* stylelint-disable-next-line */
            @media screen and (min-width: ${minVw}px) {
                ${property}: calc(
              ${minFontSize}px + ${maxFontSize - minFontSize} *
                  ((100vw - ${minVw}px) / ${maxVw - minVw})
          );
            }
            /* stylelint-disable-next-line */
            @media screen and (min-width: ${maxVw}px) {
                ${property}: ${maxFontSize}px;
            }
        `
    }

export const fluidType = fluid(fonts.sizes)(breakpoints.sm, breakpoints.lg)

export default fluid
