import type {LocaleCode} from '@/locale/locales'
import {useLocale, getDefaultLocale} from '@/providers/locale'

type Node = {
    lang: string
}

type Options = {
    localeCode?: LocaleCode
    fallbackToDefaultLocale?: boolean
}

export const useLocalizedContent = <TNode extends Node>(nodes: TNode[], options: Options = {}) => {
    const {localeCode = undefined, fallbackToDefaultLocale = true} = options
    const currentLocale = useLocale()
    const defaultLocale = getDefaultLocale()

    const getLocalized = (localeCode: LocaleCode) =>
        nodes.filter((node) => node.lang === localeCode)

    const result = getLocalized(localeCode ?? currentLocale.code)

    return result.length === 0 && fallbackToDefaultLocale
        ? getLocalized(defaultLocale.code)
        : result
}
