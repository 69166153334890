import React from 'react'

import type {Props as SignupProps} from '@/components/signup-modal'
import {SignupModal} from '@/components/signup-modal'
import useParams from '@/hooks/use-params'

const SignupModalContext = React.createContext({
    renderSignupModal: (props?: SignupProps) => {},
    isSigningUp: false,
})

export const useSignupModal = () => React.useContext(SignupModalContext)

export const SignupModalProvider = ({children}: {children: React.ReactNode}) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [isSigningUp, setIsSigningUp] = React.useState(false)
    const [modalProps, setModalProps] = React.useState<Omit<SignupProps, 'isOpen'> | undefined>()
    const queryParams = useParams()
    const showSignupModal = queryParams.get('signup') === 'open'

    React.useEffect(() => {
        setIsModalOpen(showSignupModal)
    }, [showSignupModal])

    const renderSignupModal = (props?: SignupProps) => {
        setIsModalOpen(!isModalOpen)
        setModalProps(props)
    }

    return (
        <SignupModalContext.Provider value={{renderSignupModal, isSigningUp}}>
            <SignupModal
                {...modalProps}
                isOpen={isModalOpen}
                onDismiss={() => {
                    modalProps?.onDismiss?.()
                    setIsModalOpen(false)
                }}
                onSignup={(value) => setIsSigningUp(value)}
            />
            {children}
        </SignupModalContext.Provider>
    )
}
