import * as React from 'react'

export function Close(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="m25.334 8.547-1.88-1.88L16 14.12 8.547 6.667l-1.88 1.88L14.12 16l-7.453 7.453 1.88 1.88L16 17.88l7.454 7.453 1.88-1.88L17.88 16l7.454-7.453Z"
                fill="currentColor"
            />
        </svg>
    )
}
