import React from 'react'
import styled, {css} from 'styled-components'

import {LoadingDots} from '@/components/loading-dots'
import {focusStyles} from '@/styles/utils'
import type {colors} from '@/theme'

type ButtonVariant = keyof Omit<typeof colors.button, 'disabled'>

export interface Props extends Omit<React.HTMLProps<HTMLButtonElement>, 'data'> {
    $variant?: ButtonVariant
    $loading?: boolean
    'data-testid'?: string
    as?: any
    forwardedAs?: any
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
    (
        {as: ButtonComponent = 'button', forwardedAs, children, $loading, disabled, ...props},
        ref,
    ) => (
        <ButtonComponent {...props} as={forwardedAs} disabled={disabled || $loading} ref={ref}>
            {$loading && <LoadingDots />}

            <Children $loading={$loading}>{children}</Children>
        </ButtonComponent>
    ),
)

interface ChildrenProps {
    $loading?: boolean
}

const Children = styled.span<ChildrenProps>`
    visibility: ${(props) => props.$loading && 'hidden'};
`

const StyledButton = styled(Button)<Props>(({$variant = 'default'}) => {
    const spread = $variant === 'inverted' ? 'thick' : 'thin'
    return css`
        font-family: ${({theme}) => theme.fonts.families.tertiary};
        position: relative;
        display: inline-flex;
        padding: ${({theme}) => `${theme.spacings.s14} ${theme.spacings.s28}`};
        line-height: 1;
        color: ${({theme}) => theme.colors.button[$variant].foreground};
        font-size: ${({theme}) => theme.tokens.fontLarge};
        white-space: nowrap;
        cursor: pointer;
        background: ${({theme}) => theme.colors.button[$variant].background};
        border: 1px solid ${({theme}) => theme.colors.button[$variant].border};
        border-radius: ${({theme}) => theme.radii.circle};
        justify-content: center;
        align-items: center;

        &:hover,
        &:active {
            color: ${({theme}) => theme.colors.button[$variant].hover.foreground};
            background: ${({theme}) => theme.colors.button[$variant].hover.background};
            border-color: ${({theme}) => theme.colors.button[$variant].hover.border};
        }

        &:focus-visible {
            ${focusStyles(spread)}
        }

        &:disabled {
            color: ${({theme}) => theme.colors.button.disabled.foreground};
            cursor: not-allowed;
            background: ${({theme}) => theme.colors.button.disabled.background};
            border-color: ${({theme}) => theme.colors.button.disabled.border};
        }
    `
})

export default StyledButton
