export const identify = (...args: any[]) => {
    window.analytics?.identify(...args)
}

export const page = (properties?: Record<string, any>) => {
    // Adding a delay ensures that the Segment route tracking is in sync with
    // the actual Gatsby route (otherwise you can end up in a state where the
    // Segment page tracking reports the previous page on route change).
    //
    // This is the same as it is done in `gatsby-plugin-segment-js` here:
    // https://github.com/benjaminhoffman/gatsby-plugin-segment-js/blob/master/src/gatsby-browser.js
    const DELAY_IN_MS = 50

    window.setTimeout(() => {
        window.analytics?.page(document.title, properties)
    }, DELAY_IN_MS)
}

export const getUserTraits = () => {
    const user = window.analytics?.user?.()
    const traits = user?.traits?.() || {}

    return {
        context: {
            traits,
        },
    }
}
