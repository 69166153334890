import {useState} from 'react'

interface UseSubmitReturn {
    handleSubmit: (event?: React.FormEvent<HTMLFormElement>) => Promise<void>
    loading: boolean
    error: Error | null
    clearError: () => void
}

const useSubmit = (submitFunction: Function): UseSubmitReturn => {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)

    const handleSubmit = async (...args: any[]) => {
        try {
            setLoading(true)
            setError(null)

            await submitFunction(...args)
        } catch (error) {
            setError(error as Error)
        } finally {
            setLoading(false)
        }
    }

    const clearError = () => {
        setError(null)
    }

    return {handleSubmit, loading, error, clearError}
}

export default useSubmit
