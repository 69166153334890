import React from 'react'

export interface Props {
    type: string
    message: string
}

/**
 * Hook that sends a message to the SW thread.
 */
export const useSWPostMessage = ({type, message}: Props) => {
    React.useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.controller?.postMessage({
                type,
                message,
            })
        }
    }, [type, message])
}
