import React from 'react'
import styled, {keyframes} from 'styled-components'

import type {Props as RichTextProps} from '@/components/rich-text'
import RichText, {hasRichText} from '@/components/rich-text'
import closeIcon from '@/images/icons/close-black.svg'
import {useLocale} from '@/providers/locale'
import {colors, zIndex, tokens} from '@/theme'
import {
    notificationBannerActioned,
    EventCtaActionedAction,
} from '@helios/website/src/analytics/segment'
import {useGlobalStrings} from '@helios/website/src/providers/global-strings'
import {focusStyles} from '@helios/website/src/styles/utils'

const buttonSize = '20px'

interface Props {
    content: RichTextProps['content']
}

const NotificationBannerContainer = ({content}: Props) => {
    const globalStrings = useGlobalStrings()
    const [isVisible, setIsVisible] = React.useState(true)
    const currentLocale = useLocale()

    const message = hasRichText(content) ? content : globalStrings.notification_banner_message

    if (!hasRichText(message) || !isVisible || currentLocale.isPreLaunch) {
        return null
    }

    const trackLinkClick = (event: React.MouseEvent) => {
        const target = event.target as HTMLElement
        if (target.tagName === 'A') {
            notificationBannerActioned({
                action: EventCtaActionedAction.Clicked,
            })
        }
    }

    return (
        <NotificationBanner
            message={message}
            closeButtonText={globalStrings.close_button_text}
            dismissNotificationBanner={() => setIsVisible(false)}
            onClick={trackLinkClick}
        />
    )
}

interface NotificationBannerProps {
    message: RichTextProps['content']
    closeButtonText: string
    isPersistent?: boolean
    dismissNotificationBanner: () => void
    onClick?: (event: React.MouseEvent) => void
}

export const NotificationBanner = ({
    isPersistent,
    message,
    closeButtonText,
    dismissNotificationBanner,
    onClick,
}: NotificationBannerProps) => {
    return (
        <Wrapper onClick={onClick}>
            <MessageContainer $isPersistent={isPersistent}>
                <RichText $variant="paragraph-base" content={message} $bgColor="black" />
            </MessageContainer>

            {!isPersistent && (
                <CloseButtonContainer>
                    <CloseButton
                        aria-label={closeButtonText}
                        onClick={() => dismissNotificationBanner()}
                    >
                        <img alt="" src={closeIcon} />
                    </CloseButton>
                </CloseButtonContainer>
            )}
        </Wrapper>
    )
}

const slideIn = keyframes`
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
`

const Wrapper = styled.div`
    z-index: ${zIndex.notificationBanner};
    display: flex;
    padding: ${tokens.spacing12};
    background: ${colors.banner.background};
    justify-content: flex-end;
    animation: ${slideIn} ${tokens.smooth};
`

interface MessageContainerProps {
    $isPersistent?: boolean
}

const MessageContainer = styled.div<MessageContainerProps>`
    width: 100%;
    padding-left: ${(props) => (props.$isPersistent ? 0 : buttonSize)};
    text-align: center;
`

const CloseButtonContainer = styled.div`
    display: flex;
    align-items: center;
`

const CloseButton = styled.button`
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;

    &:focus {
        ${focusStyles()}
        border-radius: ${tokens.arc4};
    }

    img {
        width: ${buttonSize};
        height: ${buttonSize};
    }
`

export default NotificationBannerContainer
