import type {LocaleCode} from '@/locale/locales'

export enum PrismicLinkTypeEnum {
    Any = 'Any',
    Document = 'Document',
    Media = 'Media',
    Web = 'Web',
    File = 'File',
}

// The standardized link formats
export type ImageLink = {
    link_type: PrismicLinkTypeEnum
    url: string
}
export type FileLink = {
    link_type: PrismicLinkTypeEnum
    url: string
}
export type WebLink = {
    link_type: PrismicLinkTypeEnum
    target?: string
    url: string
}
export type DocumentLink = {
    link_type: PrismicLinkTypeEnum
    uid?: string | null
    type: string
    lang: LocaleCode
    url?: string
    data?: {
        parent_document: {
            uid: string
        }
    }
}

// The alternative link formats
export type ImageLinkAlt = {
    _linkType: string
    url: string
}
export type FileLinkAlt = {
    _linkType: string
    url: string
}
export type WebLinkAlt = {
    _linkType: string
    url: string
    target: string
}
export type DocumentLinkAlt = {
    _linkType: string
    _meta?: {
        type: string
        lang: string
        uid: string | null
    }
    data?: {
        parent_document: {
            uid: string
        }
    }
}

export type LinkPrimary = ImageLink | WebLink | DocumentLink | FileLink
export type LinkAlt = ImageLinkAlt | WebLinkAlt | DocumentLinkAlt | FileLinkAlt
export type Link = LinkPrimary | LinkAlt

// Assert that the link is an image link (either standardized or alternate format)
export const isImageLink = (link: Link): link is ImageLink | ImageLinkAlt => {
    return (
        ('_linkType' in link && link._linkType === 'Link.image') ||
        ('link_type' in link && link.link_type === PrismicLinkTypeEnum.Media)
    )
}

// Assert that the link is a web link (either standardized or alternate format)
export const isWebLink = (link: Link): link is WebLink | WebLinkAlt => {
    return (
        ('_linkType' in link && link._linkType === 'Link.web') ||
        ('link_type' in link && link.link_type === PrismicLinkTypeEnum.Web)
    )
}

// Assert that the link is a file link
export const isFileLink = (link: Link): link is FileLink | FileLinkAlt => {
    return (
        ('_linkType' in link && link._linkType === 'Link.file') ||
        ('link_type' in link && link.link_type === PrismicLinkTypeEnum.File)
    )
}

// Assert that the link is a document link (either standardized or alternate format)
export const isDocumentLink = (link: Link): link is DocumentLink | DocumentLinkAlt => {
    return (
        ('_linkType' in link && link._linkType === 'Link.document') ||
        ('link_type' in link && link.link_type === PrismicLinkTypeEnum.Document)
    )
}

// Assert that the link is in the alternate format, in which case, we'll need to standardize it
const isAlternateFormat = (link: Link): link is LinkAlt => '_linkType' in link

// Different sources provide slightly different formats for links, so here we
// standardize the shape of the data to be passed to `PrismicLink`
export const standardizeLinkFormat = (link: Link): LinkPrimary => {
    if (isAlternateFormat(link) && isImageLink(link)) {
        const {url, _linkType, ...rest} = link
        return {
            link_type: PrismicLinkTypeEnum.Media,
            url,
            ...rest,
        }
    }

    if (isAlternateFormat(link) && isFileLink(link)) {
        const {url, _linkType, _meta, ...rest} = link
        return {
            link_type: PrismicLinkTypeEnum.File,
            url,
            ...rest,
        }
    }

    if (isAlternateFormat(link) && isWebLink(link)) {
        const {url, _linkType, _meta, target, ...rest} = link
        return {
            link_type: PrismicLinkTypeEnum.Web,
            target,
            url,
            ...rest,
        }
    }

    if (isAlternateFormat(link) && isDocumentLink(link)) {
        const {_linkType, _meta, data, ...rest} = link
        // An alternate format document link should always have a `_meta`
        // property, but we check to make sure and to keep TS happy
        if (!_meta) {
            throw new Error('Missing `_meta` in link')
        }

        return {
            link_type: PrismicLinkTypeEnum.Document,
            uid: _meta.uid,
            type: _meta.type,
            lang: _meta.lang as LocaleCode,
            data,
            ...rest,
        }
    }

    return link
}
