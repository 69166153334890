import {graphql, useStaticQuery} from 'gatsby'

import type {DisallowedEmailsQuery} from '@/graphql-types'
import {useLocalizedContent} from '@/hooks/use-localized-content'

// Checks the provided list of disallowed email patterns against the provided email address
export const checkEmailAgainst = (disallowedEmails: string[]) => (email: string) => {
    // We only test the disallowed patterns against the part of the email after the @
    const lastPartOfEmail = email.substring(email.indexOf('@') + 1)

    // This util is only concerned with checking against a list of disallowed
    // email patterns. We do basic email validation separately
    return !disallowedEmails.some((disallowedEmail) => lastPartOfEmail === disallowedEmail)
}

export const useIsCompanyEmail = (email: string) => {
    const {allPrismicDisallowedEmails} = useStaticQuery<DisallowedEmailsQuery>(query)
    const [content] = useLocalizedContent(allPrismicDisallowedEmails.nodes)

    const disallowedEmails = content.data.emails.map(({email}) => email)
    const isCompanyEmail = checkEmailAgainst(disallowedEmails)

    return isCompanyEmail(email)
}

const query = graphql`
    query DisallowedEmails {
        allPrismicDisallowedEmails {
            nodes {
                lang
                data {
                    emails {
                        email
                    }
                }
            }
        }
    }
`
