import React from 'react'

type AnchorProps = JSX.IntrinsicElements['a']

interface Props extends Omit<AnchorProps, 'href'> {
    to: AnchorProps['href']
}

const ExternalLink = ({children, to, target = '_blank', ...props}: Props) => (
    <a
        {...props}
        href={to}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
    >
        {children}
    </a>
)

export default ExternalLink
