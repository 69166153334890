import React from 'react'
import styled from 'styled-components'

interface Props extends React.HTMLProps<HTMLSpanElement> {
    as?: any
    forwardedAs?: any
}

const VisuallyHiddenComponent = React.forwardRef<HTMLSpanElement, Props>(
    ({children, as: VisuallyHiddenElement = 'span', forwardedAs, ...props}, ref) => (
        <VisuallyHiddenElement as={forwardedAs} {...props} ref={ref}>
            {children}
        </VisuallyHiddenElement>
    ),
)

export const VisuallyHidden = styled(VisuallyHiddenComponent)`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    word-wrap: normal;
    white-space: nowrap;
    border: 0;
`
