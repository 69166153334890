import React from 'react'
import styled from 'styled-components'

import Button from '@/components/button'
import {Modal, ModalContent, ModalActions} from '@/components/modal'
import RichText from '@/components/rich-text'
import {Stack} from '@/components/stack'
import Switch from '@/components/switch-ios'
import {Text} from '@/components/text'
import {ConsentState, updateConsentsState} from '@/utils/google-ads-consents'

import type {CookieBannerContent} from './cookie-banner'

interface Props {
    isOpen: boolean
    onDismiss: () => void
    onSave: (cookiesAllowed: boolean) => void
    content: CookieBannerContent
}

const CookieBannerModal = ({onSave, isOpen, onDismiss, content}: Props) => {
    const [cookiesAllowed, setCookiesAllowed] = React.useState(true)

    return (
        <Modal
            title={content.modal_title}
            image={content.modal_image}
            isOpen={isOpen}
            onDismiss={onDismiss}
        >
            <ModalContent>
                <Stack $space={8} $stretch>
                    <Inline>
                        <Text $variant="paragraph-base">{content.tracking_categories}</Text>

                        <Switch checked={cookiesAllowed} onChange={setCookiesAllowed} />
                    </Inline>

                    <RichText
                        $variant="label-base"
                        $color="muted"
                        content={content.ads_disclaimer}
                    />
                </Stack>

                <Stack $space={8} $stretch>
                    <Inline>
                        <Text $variant="paragraph-base">{content.necessary_cookies_label}</Text>

                        <Text $variant="label-base" $color="lightMuted">
                            {content.always_on_label}
                        </Text>
                    </Inline>

                    <RichText
                        $variant="label-base"
                        $color="muted"
                        content={content.necessary_cookies_disclaimer}
                    />
                </Stack>
            </ModalContent>

            <ModalActions>
                <Button
                    onClick={() => {
                        onSave(cookiesAllowed)
                        updateConsentsState(
                            cookiesAllowed ? ConsentState.Granted : ConsentState.Denied,
                        )
                    }}
                >
                    {content.modal_save_changes_button}
                </Button>
            </ModalActions>
        </Modal>
    )
}

const Inline = styled.span`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
`

export default CookieBannerModal
