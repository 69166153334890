export const paramCookiesMap = {
    referral: 'pleo_user_referrer',
    referralCode: 'pleo_referralCode',
    utmSource: 'pleo_utm_source',
    utmMedium: 'pleo_utm_medium',
    utmCampaign: 'pleo_utm_campaign',
    utmTerm: 'pleo_utm_term',
    utmContent: 'pleo_utm_content',
    latestUtmSource: 'pleo_latest_utm_source',
    latestUtmMedium: 'pleo_latest_utm_medium',
    latestUtmCampaign: 'pleo_latest_utm_campaign',
    latestUtmTerm: 'pleo_latest_utm_term',
    latestUtmContent: 'pleo_latest_utm_content',
    hubspotToken: 'hubspotutk',
    financeAdsCookieName: 'pleo-finance-ads-id',
    userUniqueIdentifier: 'uuid',
    // Meta
    metaClickId: '_fbc',
    metaBrowserId: '_fbp',
    // TikTok
    tikTokPixelId: '_ttp',
    googleClickIdentifier: 'gclid',
    //Greenhouse
    greenHouseSource: 'greenhouse_source',
    latestGreenHouseSource: 'latest_greenhouse_source',
} as const

export const cookiesMap = {
    trackingPreferences: 'tracking-preferences',
    defaultCountry: 'default_country',

    // This is the name of the cookie set in the product-web app which stores the current session status of a user.
    sessionStatusKey: 'isLoggedIn',

    // This is the name of the cookie set in the commercial-helios app which stores the historical session status of a user.
    previousSessionStatusKey: 'hasLoggedInPreviously',

    // The name of the cookie key that gets set to track the user's landing page per session
    landingPageKey: 'session:landingPage',
    rawLandingPageKey: 'session:rawLandingPage',
    landingLocale: 'session:landingLocale',

    // These three cookies get set by our Lambdas on every request
    splitTestCookieName: 'pleo-split-test',
    splitTestPathsCookieName: 'pleo-split-test-paths',
    splitTestEncounteredCookieName: 'pleo-encountered-split-tests',

    // Google Ads Consent States
    adStorage: 'ad_storage',
    adUserData: 'ad_user_data',
    adPersonalization: 'ad_personalization',
    analyticsStorage: 'analytics_storage',

    ...paramCookiesMap,
} as const

export type ParamCookiesNames = (typeof paramCookiesMap)[keyof typeof paramCookiesMap]

export type CookieCategoryID = 'necessary' | 'functional' | 'marketing' | 'performance'

export const cookieCategoriesMapping: {[key: string]: CookieCategoryID} = {
    necessary_cookies: 'necessary',
    performance_cookies: 'performance',
    functional_cookies: 'functional',
    marketing_cookies: 'marketing',
}
