import type {NavigateOptions} from '@reach/router'
import {navigate} from 'gatsby'

import {useLocale} from '@/providers/locale'

export interface StateProps {
    email?: string
    redirectPath?: string
    country?: string
    prevPath?: string
}

type Args = {
    rawPath?: boolean
}

const useNavigate = ({rawPath}: Args = {}) => {
    const currentLocale = useLocale()

    return (to: string, options?: NavigateOptions<StateProps>) => {
        const destinationPath = rawPath ? to : `/${currentLocale.urlCode}${to}`

        navigate(destinationPath, options)
    }
}

export default useNavigate
