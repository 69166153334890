/**
 * Since we use the same Prismic repository for both the website and the blog,
 * we need a way to distinguish between the two when rendering a document link
 * (e.g. inside the blog, we don't want to render a Gatsby Link with a relative
 * path pointing to a page that only exists on the website). We achieve this by
 * having separate link resolvers and returning 'UNKNOWN_DOCUMENT_TYPE' in the
 * default case. If we see this value, we know that no document matched the
 * provided type for this environment, so it must be a page in the other
 * environment, in which case we render a standard external link with an
 * absolute URL.
 */

import React from 'react'

import ExternalLink from '@/components/external-link'
import LocalizedLink from '@/components/localized-link'
import type {LinkResolver} from '@/types/link-resolver'
import {linkResolver as websiteLinkResolver} from '@/utils/link-resolver'
import localeCodeToUrl from '@/utils/locale-to-url'

import type {Link} from './utils'
import {standardizeLinkFormat, isImageLink, isWebLink, isFileLink, isDocumentLink} from './utils'

export type Props = Link & {
    className?: string
    download?: string
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

// All the possible environments. These are injected via Webpack
type Environment = 'website'

type SiteConfig = {
    resolver: LinkResolver
    url: string
}

type LinkResolverMap = Record<Environment, SiteConfig>

const linkResolverMap: LinkResolverMap = {
    website: {
        resolver: websiteLinkResolver,
        url: 'https://www.pleo.io',
    },
}
const environment = process.env.ENVIRONMENT as Environment
const siteConfig = linkResolverMap[environment]

const PrismicLink = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<Props>>(
    ({children, className, onClick, ...linkRest}, ref) => {
        if (!linkRest) {
            throw new Error(`Missing link: ${JSON.stringify(linkRest)}`)
        }

        const link = standardizeLinkFormat(linkRest)

        if (isImageLink(link) || isFileLink(link)) {
            const {url, link_type, ...rest} = link

            return (
                <ExternalLink to={url} className={className} onClick={onClick} ref={ref} {...rest}>
                    {children}
                </ExternalLink>
            )
        }

        if (isWebLink(link)) {
            const {url, target, type, link_type, lang, uid, ...rest} = link

            return (
                <ExternalLink
                    to={url}
                    target={target || '_self'}
                    className={className}
                    onClick={onClick}
                    ref={ref}
                    {...rest}
                >
                    {children}
                </ExternalLink>
            )
        }

        if (isDocumentLink(link)) {
            const {type, lang, uid, link_type, url, ...rest} = link

            if (type === 'broken_type') {
                throw new Error(`Broken link: ${JSON.stringify(link)}`)
            }

            // If the link resolver for the current environment fails,
            // we assume it's a document that doesn't exist in the current environment
            // and we check if it's a blog post or a blog homepage
            if (siteConfig?.resolver(link) === 'UNKNOWN_DOCUMENT_TYPE') {
                const blogUrlsMap: Record<string, string> = {
                    blog_homepage: `https://blog.pleo.io/${localeCodeToUrl(link.lang)}
                    `,
                    blog_post: `https://blog.pleo.io/${localeCodeToUrl(link.lang)}/${link.uid}`,
                }

                if (!blogUrlsMap[type]) {
                    throw new Error(`No document found for link: ${JSON.stringify(link)}`)
                }

                return (
                    <ExternalLink
                        to={blogUrlsMap[type]}
                        target="_blank"
                        className={className}
                        onClick={onClick}
                        ref={ref}
                        {...rest}
                    >
                        {children}
                    </ExternalLink>
                )
            }

            // If the link resolver for the current environment has a match,
            // we're safe to render a local, relative path link

            return (
                <LocalizedLink
                    rawPath
                    to={siteConfig?.resolver(link)}
                    className={className}
                    onClick={onClick}
                    ref={ref}
                    {...rest}
                >
                    {children}
                </LocalizedLink>
            )
        }

        return null
    },
)

export default PrismicLink
